import { computed, inject } from "@angular/core";
import { withStorageSync } from "@angular-architects/ngrx-toolkit";
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from "@ngrx/signals";
import {
  Course,
  CoursesState,
  FlattenedCourse,
  FlattenedPost,
  FlattenedReply,
  Post,
  Reply,
} from "../types/course.type";
import { CoursesService } from "../services/courses.service";
import { v4 as uuidv4 } from "uuid";
import { AuthService } from "../services/auth.service";
import { debounceTime } from "rxjs";
import { SnackbarService } from "../services/snackbar.service";

const initialState: CoursesState = {
  isLoading: false,
  courses: [],
  course: undefined,
  courseId: undefined,
  isSyncedWithBackend: true,
};

export const Courses = signalStore(
  { providedIn: "root" },
  withStorageSync({
    key: "MLkurser",
    storage: () => localStorage,
  }),

  withState(initialState),
  withComputed(({ courses, courseId, course }) => ({
    getCourse: computed(() => {
      const course = courses().find((course) => course.id === courseId());
      return course;
    }),
    sortPostsAndReplies: computed(() => {
      if (course()) {
        return course()
          ?.posts.sort((a, b) => {
            const dateA = new Date(a.meta.created_at).getTime() ?? 0;
            const dateB = new Date(b.meta.created_at).getTime() ?? 0;
            return dateB - dateA;
          })
          .map((post) => ({
            ...post,
            replies: post.replies.sort((a, b) => {
              const dateA = new Date(a.meta.created_at).getTime() ?? 0;
              const dateB = new Date(b.meta.created_at).getTime() ?? 0;
              return dateB - dateA;
            }),
          }));
      } else {
        return [];
      }
    }),
  })),
  withMethods(
    (
      store,
      coursesService = inject(CoursesService),
      authService = inject(AuthService),
      snackbar = inject(SnackbarService)
    ) => ({
      async getCourses() {
        patchState(store, {
          isLoading: true,
          course: undefined,
          courseId: undefined,
        });
        coursesService
          .getCourses()
          .pipe(debounceTime(300))
          .subscribe((courses) => {
            patchState(store, {
              courses,
              isLoading: false,
            });
          });
      },

      async setCourse(courseId: string) {
        patchState(store, { isLoading: true, courseId: courseId });
        const course = store.getCourse();
        if (!course) {
          snackbar.message("Kunde inte hitta kursen", 5000);
          patchState(store, {
            isLoading: false,
          });
          return;
        }
        patchState(store, {
          course: course,
        });

        const sortedPosts = store.sortPostsAndReplies();
        if (sortedPosts) course.posts = sortedPosts;

        patchState(store, {
          isLoading: false,
          course: course,
        });
      },
      async addCourse(course: Partial<Course>) {
        patchState(store, { isLoading: true, isSyncedWithBackend: false });
        try {
          const id = await coursesService.addCourse(course);

          patchState(store, {
            isLoading: false,
            isSyncedWithBackend: true,
            courseId: id,
          });
        } catch (error) {
          patchState(store, { isLoading: false });
          snackbar.message("Något gick fel..", 5000);
        }
      },
      deleteCourse(courseId: string) {
        patchState(store, { isLoading: true, isSyncedWithBackend: false });
        const updatedCourses = store
          .courses()
          .filter((course) => course.id !== courseId);
        patchState(store, {
          courses: updatedCourses,
          isLoading: false,
        });
        coursesService
          .deleteCourse(courseId)
          .then(() => {
            patchState(store, { isSyncedWithBackend: true });
          })
          .catch((error) => {
            patchState(store, { isSyncedWithBackend: true });
            snackbar.message(error.message);
          });
      },

      async addPost(courseId: string, post: Partial<Post>) {
        patchState(store, { isLoading: true, isSyncedWithBackend: false });
        const updatedCourse = store.course();
        const newId = uuidv4();
        if (updatedCourse) {
          const newPost: FlattenedPost = {
            ...post,
            id: newId,
            comment: post.comment!,
            links: post.links ?? [],
            isExpanded: false,
            isSyncedWithBackend: false,
            meta: {
              avatarURL: authService.auth.currentUser!.photoURL ?? "",
              created_by_name: authService.auth.currentUser!.displayName ?? "",
              created_by: authService.auth.currentUser!.uid,
              created_at: new Date(Date.now()),
            },
            replies: [],
          };
          const updatedPosts = [newPost, ...updatedCourse.posts];
          updatedCourse.posts = updatedPosts;
        }
        patchState(store, {
          course: updatedCourse,
          isLoading: false,
        });

        coursesService
          .addPost(courseId, post, newId)
          .then(() => {
            const course = {
              ...updatedCourse,
              posts: updatedCourse!.posts.map((p) =>
                p!.id === newId ? { ...p, isSyncedWithBackend: true } : p
              ),
            } as FlattenedCourse;
            patchState(store, {
              course: course,
              isSyncedWithBackend: true,
            });
          })
          .catch((error) => {
            const course = {
              ...updatedCourse,
              posts: updatedCourse!.posts.filter((p) => p!.id !== newId),
            } as FlattenedCourse;
            patchState(store, { isSyncedWithBackend: false, course: course });
            snackbar.message(
              "Tyvärr gick det inte att lägga till inlägget. Kontrollera din anslutning och försök igen.",
              10000
            );
          });
      },

      async deletePost(postId: string) {
        patchState(store, { isLoading: true, isSyncedWithBackend: false });
        if (
          authService.auth.currentUser?.uid === store.course()?.meta?.created_by
        ) {
          const updatedCourse = store.course();
          if (updatedCourse) {
            const updatedPosts = updatedCourse.posts.filter(
              (post) => post.id !== postId
            );
            updatedCourse.posts = updatedPosts;
          }

          patchState(store, {
            course: updatedCourse,
            isLoading: false,
          });
          coursesService
            .deletePost(store.course()!.id, postId)
            .then(() => {
              patchState(store, { isSyncedWithBackend: true });
            })
            .catch((error) => {
              patchState(store, { isSyncedWithBackend: false });
              snackbar.message(error.message);
            });
        } else {
          patchState(store, { isLoading: false });
          snackbar.message(
            "Du kan endast ta bort dina egna meddelanden.",
            5000
          );
        }
      },

      async addReply(courseId: string, postId: string, reply: Partial<Reply>) {
        console.log("adding to post", postId);
        patchState(store, { isLoading: true, isSyncedWithBackend: false });
        const updatedCourse = store.course();
        const newId = uuidv4();
        if (updatedCourse) {
          const post = updatedCourse.posts.find((post) => post.id === postId);
          if (post) {
            const newReply: FlattenedReply = {
              ...reply,
              id: newId,
              comment: reply.comment!,
              isSyncedWithBackend: false,
              meta: {
                avatarURL: authService.auth.currentUser!.photoURL as string,
                created_by_name: authService.auth.currentUser!
                  .displayName as string,
                created_by: authService.auth.currentUser!.uid,
                created_at: new Date(Date.now()),
              },
            };
            post.replies.unshift(newReply);
          }
          updatedCourse.posts = updatedCourse.posts.map((post) =>
            post.id === postId ? post : post
          );
        }
        patchState(store, {
          course: updatedCourse,
          isLoading: false,
        });
        coursesService
          .addReply(courseId, postId, reply, newId)
          .then(() => {
            const course: FlattenedCourse = {
              ...updatedCourse,
              id: store.course()!.id,
              name: store.course()!.name,
              code: store.course()!.code,
              meta: store.course()!.meta,
              posts: updatedCourse!.posts.map((p) =>
                p!.id === postId
                  ? {
                      ...p,
                      replies: p!.replies.map((r) =>
                        r!.id === newId
                          ? { ...r, isSyncedWithBackend: true }
                          : r
                      ),
                    }
                  : p
              ),
            };
            patchState(store, { course: course, isSyncedWithBackend: true });
          })
          .catch((error) => {
            patchState(store, { isSyncedWithBackend: false });
            snackbar.message(error.message);
          });
      },
      async deleteReply(postId: string, replyId: string) {
        patchState(store, { isLoading: true, isSyncedWithBackend: false });
        const updatedCourse = store.course();
        if (updatedCourse) {
          const post = updatedCourse.posts.find((post) => post.id === postId);
          if (post) {
            post.replies = post.replies.filter((reply) => reply.id !== replyId);
          }
          updatedCourse.posts = updatedCourse.posts.map((post) =>
            post.id === postId ? post : post
          );
        }

        patchState(store, {
          course: updatedCourse,
          isLoading: false,
        });
        coursesService
          .deleteReply(store.course()!.id, postId, replyId)
          .then(() => {
            patchState(store, { isSyncedWithBackend: true });
          })
          .catch((error) => {
            patchState(store, { isSyncedWithBackend: false });
            snackbar.message(error.message);
          });
      },

      async movePostOnTop(postId: string) {},
    })
  )
);
