import { inject, Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import {
  collection,
  DocumentData,
  query,
  addDoc,
  Timestamp,
  doc,
  where,
  onSnapshot,
  initializeFirestore,
  updateDoc,
  deleteField,
  deleteDoc,
} from "firebase/firestore";
import { Course, FlattenedCourse, Post, Reply } from "../types/course.type";
import { Observable, Observer } from "rxjs";
import { Assignment } from "../types/assignment.type";
import { RandomCodeUtil } from "../utils/code-generator";

@Injectable({
  providedIn: "root",
})
export class CoursesService {
  authService = inject(AuthService);
  codeGenerator = new RandomCodeUtil();
  db = initializeFirestore(this.authService.app, {});

  getCourses(): Observable<FlattenedCourse[]> {
    const q = query(
      collection(this.db, "courses"),
      where("meta.created_by", "==", this.authService.auth.currentUser!.uid)
    );
    return new Observable((observer: Observer<FlattenedCourse[]>) => {
      onSnapshot(q, (querySnapshot) => {
        const courses: Course[] = [];

        querySnapshot.forEach((doc) => {
          const course = { id: doc.id, ...doc.data() } as Course;
          if (course.meta && course.meta.created_at) {
            course.meta.created_at = (
              course.meta.created_at as unknown as Timestamp
            ).toDate();
          }
          if (course.meta && course.meta.updated_at) {
            course.meta.updated_at = (
              course.meta.updated_at as unknown as Timestamp
            ).toDate();
          }
          if (course.posts) {
            course.posts = Object.fromEntries(
              Object.entries(course.posts).map(([id, post]) => [
                id,
                { ...post, id: id },
              ])
            );

            Object.values(course.posts).forEach((post) => {
              if (post.meta && post.meta.created_at) {
                post.meta.created_at = (
                  post.meta.created_at as unknown as Timestamp
                ).toDate();
              }
              if (post.meta && post.meta.updated_at) {
                post.meta.updated_at = (
                  post.meta.updated_at as unknown as Timestamp
                ).toDate();
              }
              if (post.assignments_added) {
                this.getAssignment(post.assignments_added).then(
                  (assignments) => {
                    post.assignments_data = assignments;
                  }
                );
              }
              if (post.replies) {
                post.replies = Object.fromEntries(
                  Object.entries(post.replies).map(([id, reply]) => [
                    id,
                    { ...reply, id: id },
                  ])
                );
                Object.values(post.replies).forEach((reply) => {
                  if (reply.meta && reply.meta.created_at) {
                    reply.meta.created_at = (
                      reply.meta.created_at as unknown as Timestamp
                    ).toDate();
                  }
                  if (reply.meta && reply.meta.updated_at) {
                    reply.meta.updated_at = (
                      reply.meta.updated_at as unknown as Timestamp
                    ).toDate();
                  }
                });
              }
            });
          }
          courses.push(course);
        });

        const flattenedCourses = courses.map((course) => {
          return {
            id: course.id,
            name: course.name,
            class: course.class,
            chapter: course.chapter,
            meta: course.meta,
            code: course.code,
            posts: course.posts
              ? Object.values(course.posts).map((post) => {
                  return {
                    id: post.id,
                    comment: post.comment,
                    links: post.links ?? [],
                    isSyncedWithBackend: post.isSyncedWithBackend,
                    isExpanded: post.isExpanded,
                    meta: post.meta,
                    replies: post.replies ? Object.values(post.replies) : [],
                    assignments_data: post.assignments_data,
                  };
                })
              : [],
          };
        });
        flattenedCourses.sort((a, b) => {
          if (a.meta.created_at && b.meta.created_at) {
            return b.meta.created_at.getTime() - a.meta.created_at.getTime();
          }
          return 0;
        });
        observer.next(flattenedCourses);
      });
    });
  }

  deleteCourse(courseId: string): Promise<void> {
    const docRef = doc(this.db, "courses", courseId);
    return deleteDoc(docRef);
  }

  async getAssignment(
    assignmentIds: string[] | undefined
  ): Promise<Record<string, Assignment>> {
    if (assignmentIds === undefined) {
      return Promise.resolve({});
    }
    const q = query(
      collection(this.db, "assignments"),
      where("__name__", "in", assignmentIds)
    );
    return new Promise((resolve) => {
      onSnapshot(q, (querySnapshot) => {
        const data = Object.fromEntries(
          querySnapshot.docs.map((doc) => [doc.id, doc.data() as Assignment])
        );
        resolve(data);
      });
    });
  }

  addAssignment(assignment: Partial<Assignment>): Promise<DocumentData> {
    const docRef = collection(this.db, "assignments");
    const updatedAssignment = this.addCreatorMetaData(assignment);
    return addDoc(docRef, updatedAssignment);
  }

  async addCourse(course: Partial<Course>): Promise<string> {
    const updatedCourse = this.addCreatorMetaData(course);
    updatedCourse.posts = {};
    updatedCourse.code = this.codeGenerator.generateRandomString(6);
    const docRef = await addDoc(collection(this.db, "courses"), updatedCourse);
    return docRef.id;
  }

  addPost(courseId: string, post: Partial<Post>, newId: string) {
    const docRef = doc(this.db, "courses", courseId);
    const updatedPost = this.addCreatorMetaData(post);
    updatedPost.isSyncedWithBackend = true;
    return updateDoc(docRef, {
      [`posts.${newId}`]: {
        ...updatedPost,
      },
    });
  }

  addReply(
    courseId: string,
    postId: string,
    reply: Partial<Reply>,
    newId: string
  ) {
    const docRef = doc(this.db, "courses", courseId);
    const updatedReply = this.addCreatorMetaData(reply);
    updatedReply.isSyncedWithBackend = true;

    return updateDoc(docRef, {
      [`posts.${postId}.replies.${newId}`]: updatedReply,
    });
  }

  deletePost(courseId: string, postId: string) {
    const docRef = doc(this.db, "courses", courseId);
    return updateDoc(docRef, {
      [`posts.${postId}`]: deleteField(),
    });
  }
  deleteReply(courseId: string, postId: string, replyId: string) {
    const docRef = doc(this.db, "courses", courseId);
    return updateDoc(docRef, {
      [`posts.${postId}.replies.${replyId}`]: deleteField(),
    });
  }

  private addCreatorMetaData<T>(obj: Partial<T>): Partial<T> & {
    meta: {
      avatarURL: string;
      created_by: string;
      created_by_name: string;
      created_at: Timestamp;
    };
  } {
    return {
      ...obj,
      meta: {
        avatarURL: this.authService.auth.currentUser!.photoURL as string,
        created_by: this.authService.auth.currentUser!.uid,
        created_by_name: this.authService.auth.currentUser!
          .displayName as string,
        created_at: Timestamp.now(),
      },
    };
  }
}
